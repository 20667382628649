import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import bigImg from "../../resources/img/Main/big.jpg";
import smallImg from "../../resources/img/Main/small.jpg";

import "./AdvantageSection.scss";

function AdvantageSection() {
  const { t } = useTranslation();

  return (
    <section className="advantage py">
      <Container>
        <Row className="advantage__pics g-5">
          <Col md={6} className="advantage__img">
            <img
              src={bigImg}
              alt="advantage"
              className="advantage__img-big"
              data-aos="zoom-in"
            />
            <img
              src={smallImg}
              alt="advantage"
              className="advantage__img-small"
              data-aos="fade-up"
            />
          </Col>

          <Col md={6} className="ps-5">
            <h5 data-aos="fade-up" data-aos-duration="1000">
              {t("index.advantage.title")}
            </h5>

            <h3 data-aos="fade-up" data-aos-duration="1500">
              {t("index.advantage.header")}
            </h3>

            <p data-aos="fade-up" data-aos-duration="2000">
              {t("index.advantage.body")}
            </p>

            <Link
              className="button"
              data-aos="fade-up"
              data-aos-duration="3000"
              to="/about"
            >
              {t("index.advantage.details")}
            </Link>
          </Col>
        </Row>
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/WCBixPkmxeA"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Container>
    </section>
  );
}

export default AdvantageSection;

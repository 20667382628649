import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Investors.scss";

const data = [
  {
    title: "investors.data0.title",
    parag: "investors.data0.parag",
    animation: "fade-right",
  },
  {
    title: "investors.data1.title",
    parag: "investors.data1.parag",
    animation: "fade-left",
  },
  {
    title: "investors.data2.title",
    parag: "investors.data2.parag",
    animation: "fade-right",
  },
  {
    title: "investors.data3.title",
    parag: "investors.data3.parag",
    animation: "fade-left",
  },
];

export default function Investors() {
  const [t] = useTranslation();

  return (
    <section className="investors">
      <div className="investors__header">
        <div className="investors__overlay">
          <Container>
            <h1 className="animate__animated animate__fadeIn animate__slow">
              {t("investors.title")}
            </h1>
            <h4 className="animate__animated animate__fadeInRightBig animate__slow">
              {t("investors.slogan")}
            </h4>
          </Container>
        </div>
      </div>

      <div className="investors__parag">
        <Container>
          <Row>
            {data.map((item, index) => (
              <Col md={6} key={index} data-aos={item.animation}>
                <h4>{t(item.title)}</h4>
                <p>{t(item.parag)}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className="investors__callback">
        <div className="investors__callback-box">
          <h6>Orient Technology</h6>
          <h3>{t("investors.bottom.slogan")}</h3>
          <p>
            {t("investors.bottom.call")} <br />
            {t("investors.bottom.phone")} (8361) 226 27 94
          </p>

          <Link className="button" to="#">
            {t("investors.bottom.contacts")}
          </Link>
        </div>
      </div>
    </section>
  );
}

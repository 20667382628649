import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import krast1 from "../../resources/img/Production/krast-1.jpg";
import krast2 from "../../resources/img/Production/krast-2.jpg";
import krast3 from "../../resources/img/Production/krast-3.jpg";
import krast4 from "../../resources/img/Production/krast-4.jpg";
import krast5 from "../../resources/img/Production/krast-5.jpg";
import imgLeft from "../../resources/img/Production/Krast.jpg";
import AccordionKrasta from "../Accordion/AccordionKrasta";
import "./Production.scss";

const data = [
  {
    image: krast1,
  },
  {
    image: krast2,
  },
  {
    image: krast3,
  },
  {
    image: krast4,
  },
  {
    image: krast5,
  },
];

export default function ProductionKrasta(props) {
  const [t] = useTranslation();

  return (
    <section className="production">
      <div className="production__header production__header--krast">
        <div className="production__overlay">
          <Container>
            <h1 className="animate__animated animate__fadeInRightBig animate__slow">
              {t("production.crust.title")}
            </h1>
          </Container>
        </div>
      </div>

      <Container className="py-5">
        <div className="production__title">
          <h2>{t("production.crust.header0")}</h2>

          <button className="button border-0">{t("production.detail")}</button>

          <img src={imgLeft} width="100%" alt="" />
        </div>

        <div className="production__accordion">
          <h2 className="mt-5 mb-4">{t("production.crust.header1")}</h2>
          <Row>
            <Col md={6}>
              <div className="production__accordion-box">
                <AccordionKrasta
                  title1={t("production.crust.list0.title1")}
                  title2={t("production.crust.list0.title2")}
                  title3={t("production.crust.list0.title3")}
                  title4={t("production.crust.list0.title4")}
                  title5={t("production.crust.list0.title5")}
                  title6={t("production.crust.list0.title6")}
                  title7={t("production.crust.list0.title7")}
                  text1={t("production.crust.list0.text1")}
                  text2={t("production.crust.list0.text2")}
                  text3={t("production.crust.list0.text3")}
                  text4={t("production.crust.list0.text4")}
                  text5={t("production.crust.list0.text5")}
                  text6={t("production.crust.list0.text6")}
                  text7={t("production.crust.list0.text7")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="production__accordion-box">
                <AccordionKrasta
                  title1={t("production.crust.list1.title1")}
                  title2={t("production.crust.list1.title2")}
                  title3={t("production.crust.list1.title3")}
                  title4={t("production.crust.list1.title4")}
                  title5={t("production.crust.list1.title5")}
                  title6={t("production.crust.list1.title6")}
                  title7={t("production.crust.list1.title7")}
                  text1={t("production.crust.list1.text1")}
                  text2={t("production.crust.list1.text2")}
                  text3={t("production.crust.list1.text3")}
                  text4={t("production.crust.list1.text4")}
                  text5={t("production.crust.list1.text5")}
                  text6={t("production.crust.list1.text6")}
                  text7={t("production.crust.list1.text7")}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <div className="production__gallery">
        <Container>
          <h5>Orient Technology</h5>

          <h3>{t("production.crust.header2")}</h3>
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            navigation={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="SwiperGallery"
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} width="100%" height="300" alt="Одежда" />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>
    </section>
  );
}

import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import img2 from "../../resources/img/Main/9E4A1001.jpg";
import img3 from "../../resources/img/Main/9E4A1024.jpg";
import img1 from "../../resources/img/Main/bg-intro.jpg";

import "animate.css";
import "aos/dist/aos.css";
import "./IntroSection.scss";

function IntroSection() {
  const { t } = useTranslation();

  return (
    <section className="intro">
      <div id="cf">
        <img className="bottom" src={img3} />
        <img className="center" src={img2} />
        <img className="top" src={img1} />
      </div>

      <Container className="intro__overlay">
        <h1 className="animate__animated animate__fadeIn animate__slower">
          Orient Technology
        </h1>

        <h2 className="animate__animated animate__fadeInRightBig animate__slow ">
          {t("index.intro.slogan")}
        </h2>

        <p className="animate__animated animate__fadeInRightBig animate__slow animate__delay-1s">
          {t("index.intro.text")}
        </p>

        <Link
          className="button animate__animated animate__fadeInRightBig animate__slow animate__delay-2s"
          to="/about"
        >
          {t("index.intro.details")}
        </Link>
      </Container>
    </section>
  );
}

export default IntroSection;

import { useCallback, useContext, useMemo, useState } from "react";
import { Container, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import LanguageContext from "../../context/LanguageContext";
import { getAvialableLangs } from "../../hooks/useLang";
import useMenu from "../../hooks/useMenu";
import holding from "../../resources/img/holding.svg";
import logo from "../../resources/img/Orient Technology Logo .svg";

import "./Header.scss";

const MyNavDropdown = ({ item }) => {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const location = useLocation();

  return (
    <span
      to={item.link}
      className={location.pathname.startsWith(item.link) ? "active p-0" : "p-0"}
    >
      <NavDropdown
        id="nav-dropdown-dark-example"
        show={show}
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
        title={
          item.link === "/about" ? (
            <NavLink to={item.link}>{item.title}</NavLink>
          ) : (
            <>{item.title}</>
          )
        }
        renderMenuOnMount={true}
        className="item"
      >
        {item.children.map((item, i) => (
          <NavLink
            to={item.link}
            className={({ isActive }) =>
              isActive ? "dropdown-item active" : "dropdown-item"
            }
            key={i}
          >
            {item.title}
          </NavLink>
        ))}
      </NavDropdown>
    </span>
  );
};

const Header = () => {
  const [language, setLanguage] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const menuLi = useMenu();

  const changeLang = useCallback((lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, []);

  // Dropdown

  const handleClick = useCallback(() => {
    setOpen((open) => !open);

    document.body.classList.toggle("active");
  }, []);

  return (
    <View
      onChangeLang={changeLang}
      onOpen={handleClick}
      data={{ language, menuLi, open, t }}
    />
  );
};

const View = (props) => {
  const data = useMemo(() => props.data, [props.data]);
  return (
    <header className="header">
      <Container>
        <div className={"menu" + (data.language === "uz" ? " menu--uz" : "")}>
          <Link to="/" className="d-flex align-items-center menu__brand">
            <img src={logo} className="menu__brand-img" />
          </Link>
          {/* Hamburger button */}

          <div
            className={data.open ? "menu__hamburger active" : "menu__hamburger"}
            onClick={props.onOpen}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          {/* Logo */}

          {/* Menu */}

          <ul className="menu__list">
            {data.menuLi.getLi.map((item, index) => (
              <li className="menu__item" key={index}>
                {!item.children ? (
                  <NavLink
                    to={item.link}
                    className={({ isActive }) => (isActive ? "active" : null)}
                  >
                    {item.title}
                  </NavLink>
                ) : (
                  <MyNavDropdown item={item} />
                )}
              </li>
            ))}

            <li className="menu__lang">
              {/* Language Dropdown */}

              <NavDropdown
                id="nav-dropdown-dark-example"
                title={<>{data.language}</>}
                onSelect={(e) => {
                  props.onChangeLang(e);
                }}
                renderMenuOnMount={true}
              >
                {getAvialableLangs().map((lang, index) => {
                  return data.language !== lang ? (
                    <NavDropdown.Item key={index} eventKey={lang}>
                      {lang}
                    </NavDropdown.Item>
                  ) : null;
                })}
              </NavDropdown>
            </li>
          </ul>

          {/* Mobile Menu */}

          <div className={data.open ? "mob-menu active" : "mob-menu"}>
            <div className="mob-menu__content">
              <div className="mob-menu__header">
                {/* Social */}

                <div className="mob-menu__social">
                  <Link to="/">
                    <span>Orient Technology</span>
                  </Link>
                </div>
              </div>

              <div className="mob-menu__body">
                <ul className="mob-menu__list">
                  {data.menuLi.getMobLi.map((item, index) => (
                    <li className="mob-menu__item" key={index}>
                      <NavLink
                        to={item.link}
                        onClick={props.onOpen}
                        className={({ isActive }) =>
                          isActive ? "active" : null
                        }
                      >
                        {" "}
                        {item.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>

                <ul className="mob-menu__lang">
                  {getAvialableLangs().map((lang, index) => {
                    return (
                      <li
                        key={index}
                        onClick={(e) =>
                          props.onChangeLang(e.currentTarget.textContent)
                        }
                        className={data.language === lang ? "active" : null}
                      >
                        {lang}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="overlay" onClick={props.onOpen}></div>
          </div>

          <a
            href="https://orientgroup.uz/"
            target="_blank"
            className="d-flex align-items-center menu__holding"
            rel="noreferrer"
          >
            <img src={holding} className="menu__holding-img" />
          </a>
        </div>
      </Container>
    </header>
  );
};

export default Header;

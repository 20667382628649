import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import imgLeft from "../../resources/img/Production/Krast.jpg";
import leather1 from "../../resources/img/Production/leather-1.jpg";
import leather2 from "../../resources/img/Production/leather-2.jpg";
import leather3 from "../../resources/img/Production/leather-3.jpg";
import BasicTabs from "../Tab/Tab";
import "./Production.scss";

const data = [
  {
    image: leather1,
  },
  {
    image: imgLeft,
  },
  {
    image: leather2,
  },
  {
    image: leather3,
  },
  {
    image: leather1,
  },
];

export default function ProductionLeather() {
  const [t] = useTranslation();

  return (
    <section className="production">
      <div className="production__header production__header--leather">
        <div className="production__overlay">
          <Container>
            <h1 className="animate__animated animate__fadeInRightBig animate__slow">
              {t("production.leather.title")}
            </h1>
          </Container>
        </div>
      </div>

      <Container className="py-5">
        <div className="production__title">
          <h2>{t("production.leather.header0")}</h2>

          <button className="button border-0">{t("production.detail")}</button>

          <img src={imgLeft} width="100%" alt="" />
        </div>

        <div className="production__accordion">
          <h2 className="mt-5 mb-4">{t("production.leather.header1")}</h2>

          <BasicTabs />
        </div>
      </Container>

      <div className="production__gallery">
        <Container>
          <h5>Orient Technology</h5>

          <h3>{t("production.leather.header2")}</h3>
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            navigation={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="SwiperGallery"
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} width="100%" height="300" alt="Одежда" />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>
    </section>
  );
}

import { useTranslation } from "react-i18next";

function useMenu() {
  const { t } = useTranslation();

  const getLi = [
    {
      link: "/",
      title: t("header.home"),
    },
    {
      link: "/about",
      title: t("header.aboutUs"),
      children: [
        {
          link: "/about/administration",
          title: t("header.administration"),
        },
      ],
    },
    {
      link: "/products",
      title: t("header.products"),
    },
    {
      link: "/production",
      title: t("header.production"),
      children: [
        {
          link: "/production/wetblue",
          title: t("header.wetBlue"),
        },
        {
          link: "/production/krast",
          title: t("header.krast"),
        },
        {
          link: "/production/leather",
          title: t("header.leather"),
        },
      ],
    },
    {
      link: "/news",
      title: t("header.news"),
    },
    {
      link: "/investors",
      title: t("header.investors"),
    },
    {
      link: "/contacts",
      title: t("header.contacts"),
    },
  ];

  const getMobLi = [
    {
      link: "/",
      title: t("header.home"),
    },
    {
      link: "/about",
      title: t("header.aboutUs"),
    },
    {
      link: "/about/administration",
      title: t("header.administration"),
    },
    {
      link: "/products",
      title: t("header.products"),
    },
    {
      link: "/production/wetblue",
      title: t("header.wetBlue"),
    },
    {
      link: "/production/krast",
      title: t("header.krast"),
    },
    {
      link: "/production/leather",
      title: t("header.leather"),
    },
    {
      link: "/investors",
      title: t("header.investors"),
    },
    {
      link: "/contacts",
      title: t("header.contacts"),
    },
  ];

  return { getLi, getMobLi };
}

export default useMenu;

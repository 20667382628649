import { useTranslation } from "react-i18next";
import MyForm from "../Form/MyForm";

import { Col, Container, Row } from "react-bootstrap";

import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import contact from "../../resources/img/Contacts/notbook.jpg";

import "./ContactsPage.scss";

function ContactsPage() {
  const { t } = useTranslation();

  return (
    <section className="contacts">
      <div className="contacts__header">
        <div className="contacts__overlay">
          <Container>
            <h4 className="animate__animated animate__fadeIn animate__slow">
              Orient Technology
            </h4>
            <h1 className="animate__animated animate__fadeInRightBig animate__slow">
              {t("contacts.title")}
            </h1>
          </Container>
        </div>
      </div>

      <Container className="p-5">
        <Row>
          <Col md={6} className="p-5">
            <img src={contact} className="pe-4" width="100%" alt="" />
          </Col>
          <Col md={6} className="pt-4">
            <MyForm
              rightClass={"contacts-form-info"}
              class={"form-info-inner"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div className="contacts__box">
              <h5>{t("contacts.bottom0.title")}</h5>

              <div className="d-flex">
                <LocationOnIcon />
                <div>
                  <h6>{t("contacts.bottom0.text")}</h6>
                </div>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="contacts__box">
              <h5>{t("contacts.bottom1.title")}</h5>

              <div className="d-flex">
                <PhoneIphoneIcon />
                <div>
                  <h6>{t("contacts.bottom1.text")}</h6>
                </div>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="contacts__box">
              <h5>{t("contacts.bottom2.title")}</h5>

              <div className="d-flex">
                <EmailIcon />
                <div>
                  <h6>{t("contacts.bottom2.text")}</h6>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2944.8627538734017!2d59.647197339950566!3d42.430656163881956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zNDLCsDI1JzUxLjUiTiA1OcKwMzgnNDguMyJF!5e0!3m2!1sru!2s!4v1666167572035!5m2!1sru!2s"
          width="100%"
          height="500"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Container>
    </section>
  );
}

export default ContactsPage;

import AboutSection from "../Sections/AboutSection";
import AdvantageSection from "../Sections/AdvantageSection";
import IntroSection from "../Sections/IntroSection";
import Productsection from "../Sections/Productsection";


export default function MainPage() {
    return (
        <>
            <IntroSection />
            <AdvantageSection />
            <AboutSection />
            <Productsection />
        </>
    )
}
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";

import imgLeft from "../../resources/img/About/9E4A0752.jpg";
import imgRight from "../../resources/img/About/work.jpg";

import "./About.scss";

export default function AboutPage() {
  const [t] = useTranslation();

  return (
    <section className="about">
      <div className="about__header">
        <div className="about__overlay">
          <Container>
            <h4 className="animate__animated animate__fadeIn animate__slow">
              {t("about.intro.title")}
            </h4>

            <h1 className="animate__animated animate__fadeInRightBig animate__slow">
              ORIENT TECHNOLOGY
            </h1>

            <h2 className="animate__animated animate__fadeInRightBig animate__slow animate__delay-1s">
              {t("about.intro.text")}
            </h2>
          </Container>
        </div>
      </div>

      <Container className="py-5">
        <Row>
          <Col md={6} className="d-flex p-5">
            <img width="100%" src={imgLeft} data-aos="fade-right" alt="Завод" />
          </Col>

          <Col md={6} className="pt-5">
            <h2 data-aos="fade-left" data-aos-duration="1000">
              {t("about.body.data0.title")}
            </h2>
            <p data-aos="fade-left" data-aos-duration="1600">
              {t("about.body.data0.text")}
            </p>
          </Col>

          <Col md={6} className="pt-5">
            <h2 data-aos="fade-right" data-aos-duration="1000">
              {t("about.body.data1.title")}
            </h2>
            <p
              data-aos="fade-right"
              data-aos-duration="1600"
              className="about__parag"
            >
              {t("about.body.data1.text")}
            </p>
          </Col>

          <Col md={6} className="d-flex p-4">
            <img width="100%" data-aos="fade-left" src={imgRight} alt="Завод" />
          </Col>
        </Row>
      </Container>

      <div className="about-counter">
        <div className="about-counter-layout">
          <h1 className="text-center">{t("about.counter.title")}</h1>
          <Row>
            <Col md={4}>
              <CountUp
                end={7}
                duration={2.75}
                useEasing={true}
                useGrouping={true}
                separator=" "
                suffix={t("about.counter.data0.unit")}
                prefix={t("about.counter.data0.unitPrefix")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <h6>{t("about.counter.data0.name")}</h6>
            </Col>
            <Col md={4}>
              <CountUp
                end={240000}
                duration={2.75}
                useEasing={true}
                useGrouping={true}
                separator=" "
                suffix={t("about.counter.data1.unit")}
                prefix={t("about.counter.data1.unitPrefix")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <h6>{t("about.counter.data1.name")}</h6>
            </Col>
            <Col md={4}>
              <CountUp
                end={80000000}
                duration={2.75}
                useEasing={true}
                useGrouping={true}
                separator=" "
                suffix={t("about.counter.data2.unit")}
                prefix={t("about.counter.data2.unitPrefix")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <h6>{t("about.counter.data2.name")}</h6>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

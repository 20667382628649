import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import imgLeft from "../../resources/img/Production/9E4A0659.jpg";
import imgLeft1 from "../../resources/img/Production/9E4A0676.jpg";

import imgRight from "../../resources/img/Production/9E4A1017.jpg";
import imgRight1 from "../../resources/img/Production/9E4A1019.jpg";

import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import imgBottom1 from "../../resources/img/Production/05.jpg";
import imgBottom from "../../resources/img/Production/bb255ff60222e086aa6a.jpg";

import "swiper/css";
import "swiper/css/pagination";
import "./ProductsPage.scss";

export default function ProductsPage() {
  const [t] = useTranslation();

  return (
    <section className="products">
      <div className="products__header">
        <div className="products__overlay">
          <Container>
            <h1 className="animate__animated animate__fadeInRightBig animate__slow">
              {t("products.title")}
            </h1>
          </Container>
        </div>
      </div>

      <Container className="py-5">
        <Row>
          <Col sm={12}>
            <h2 data-aos="fade-up" className="text-center fw-bold">
              {t("products.data0.title")}
            </h2>
          </Col>
          <Col md={4} className="pt-5">
            <h2 data-aos="fade-right" data-aos-duration="1000">
              {t("products.data0.text")}
            </h2>
          </Col>
          <Col md={8} className="d-flex p-5">
            <Swiper
              slidesPerView={1}
              pagination={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              className="SwiperGallery"
            >
              <SwiperSlide>
                <img src={imgLeft} width="100%" alt="Завод" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={imgLeft1} width="100%" alt="Завод" />
              </SwiperSlide>
            </Swiper>
            {/* <img width='100%' src={imgLeft} data-aos="fade-left" alt="Завод" /> */}
          </Col>

          <Col sm={12}>
            <h2 data-aos="fade-up" className="text-center fw-bold">
              {t("products.data1.title")}
            </h2>
          </Col>
          <Col md={8} className="d-flex p-4">
            <Swiper
              slidesPerView={1}
              pagination={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              className="SwiperGallery"
            >
              <SwiperSlide>
                <img src={imgRight} width="100%" alt="Завод" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={imgRight1} width="100%" alt="Завод" />
              </SwiperSlide>
            </Swiper>
          </Col>
          <Col md={4} className="pt-5">
            <h2 data-aos="fade-left" data-aos-duration="1000">
              {t("products.data1.text")}
            </h2>
            <p
              data-aos="fade-rigleftht"
              data-aos-duration="1600"
              className="about__parag"
            >
              {t("products.data1.description")}
            </p>
          </Col>

          <Col sm={12}>
            <h2 data-aos="fade-up" className="text-center fw-bold">
              {t("products.data2.title")}
            </h2>
          </Col>
          <Col md={4} className="pt-5">
            <h2 data-aos="fade-right" data-aos-duration="1000">
              {t("products.data2.text")}
            </h2>
            <p
              data-aos="fade-right"
              data-aos-duration="1600"
              className="about__parag"
            >
              {t("products.data2.description")}
            </p>
          </Col>
          <Col md={8} className="d-flex p-5">
            <Swiper
              slidesPerView={1}
              pagination={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              className="SwiperGallery"
            >
              <SwiperSlide>
                <img src={imgBottom} width="100%" alt="Завод" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={imgBottom1} width="100%" alt="Завод" />
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../resources/img/Orient Technology Logo .svg";
import "./Footer.scss";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__overlay">
        <Container>
          <Row>
            <Col md={4}>
              <img src={logo} alt="Shabnam elegant" />

              <p className="w-75">{t("footer.slogan")}</p>
            </Col>
            <Col md={4}>
              <h3>{t("footer.company")}</h3>

              <div className="footer__link">
                <Link to="/">{t("footer.company.aboutUs")}</Link>
                <Link to="/">{t("footer.company.Production")}</Link>
                <Link to="/investors">{t("footer.company.investors")}</Link>
                <Link to="/contacts">{t("footer.company.contacts")}</Link>
              </div>
            </Col>
            <Col md={4}>
              <h3>{t("footer.contactUs")}</h3>

              <div className="footer__link">
                <a href="tel:+83612262794">(8361) 226 27 94</a>
                <a href="mailto:info@orienttechonologyuz.com">
                  info@orienttechonologyuz.com
                </a>
              </div>

              <h3 className="mt-5">{t("footer.address")}</h3>

              <div className="footer__link">
                <p>{t("footer.address.value")}</p>
              </div>
            </Col>

            <Col className="footer__bottom">
              <span>ORIENT TECHNOLOGY 2022</span>

              <a href="https://wtma.uz/">Website developer wtma.uz</a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;

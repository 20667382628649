import { useTranslation } from "react-i18next";

import { Col, Container, Row } from "react-bootstrap";
import news1 from "../../resources/img/News/news1.jpg";
import news2 from "../../resources/img/News/news2.jpg";
import news3 from "../../resources/img/News/news3.jpg";

import { Link } from "react-router-dom";
import "./Productsection.scss";

const data = [
  {
    img: news1,
    title: "index.news.data0.title",
    parag: "index.news.data0.parag",
  },
  {
    img: news2,
    title: "index.news.data1.title",
    parag: "index.news.data1.parag",
  },
  {
    img: news3,
    title: "index.news.data2.title",
    parag: "index.news.data2.parag",
  },
];

function Productsection() {
  const { t } = useTranslation();

  return (
    <section className="productsection">
      <h4>{t("index.news.title")}</h4>

      <h2>{t("index.news.header")}</h2>

      <Container>
        <Row>
          {data.map((item, index) => (
            <Col md={4} key={index} className="mb-4">
              <div className="productsection__box h-100">
                <div className="productsection__box-parag">
                  <Link to="/news">
                    <img src={item.img} width="100%" height="250" alt="?" />
                  </Link>
                  <h3>
                    <Link className="link-title" to="/news">
                      {t(item.title)}
                    </Link>
                  </h3>

                  <p>{t(item.parag)}</p>
                </div>
                <Link className="link-button" to="/news">
                  {t("index.news.readMore")}
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Productsection;

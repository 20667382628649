import { Suspense, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import LanguageContext from "../../context/LanguageContext";
import ScrollTop from "../ScrollTop/ScrollTop";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import AOS from "aos";
import { useEffect } from "react";
import Page404 from "../404/404";
import MainPage from "../Pages";
import AboutPage from "../Pages/About";
import ContactsPage from "../Pages/ContactsPage";
import Investors from "../Pages/Investors";
import News from "../Pages/News";
import Production from "../Pages/Production";
import ProductionKrasta from "../Pages/ProductionKrasta";
import ProductionLeather from "../Pages/ProductionLeather";
import ProductsPage from "../Pages/ProductsPage";

import "animate.css";
import "animate.css/animate.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/Buttons.scss";
import "../../styles/Forms.scss";
import "../../styles/Various.scss";
import "../../styles/_main.scss";
import AboutAdmin from "../Pages/AboutAdmin";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "ru"
  );

  return (
    <Router>
      <Suspense fallback="">
        <LanguageContext.Provider value={[language, setLanguage]}>
          <Header />
          <main buildtime={Date.now()}>
            <ScrollTop>
              <Routes>
                <Route exact path="/" element={<MainPage />} />
                <Route exact path="/about" element={<AboutPage />} />
                <Route
                  exact
                  path="/about/administration"
                  element={<AboutAdmin />}
                />
                <Route exact path="/products" element={<ProductsPage />} />
                <Route
                  exact
                  path="/production/wetblue"
                  element={<Production />}
                />
                <Route
                  exact
                  path="/production/krast"
                  element={<ProductionKrasta />}
                />
                <Route
                  exact
                  path="/production/leather"
                  element={<ProductionLeather />}
                />
                <Route exact path="/news" element={<News />} />
                <Route exact path="/investors" element={<Investors />} />
                <Route exact path="/contacts" element={<ContactsPage />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </ScrollTop>
          </main>
          <ScrollToTop smooth top="400" color="#c31f1f" />
          <Footer />
        </LanguageContext.Provider>
      </Suspense>
    </Router>
  );
}

export default App;

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import buxgalter from "../../resources/img/About/buxgalter.jpg";
import ceo from "../../resources/img/About/ceo.jpg";
import gendir from "../../resources/img/About/gendir.jpg";
import glavtex from "../../resources/img/About/glavtex.jpg";
import komdir from "../../resources/img/About/komdir.jpg";
import tex from "../../resources/img/About/tex.jpg";

import "./About.scss";

const admins = [
  {
    name: "about.administration.data0.name",
    position: "about.administration.data0.position",
    quote: "about.administration.data0.quote",
    img: ceo,
  },
  {
    name: "about.administration.data1.name",
    position: "about.administration.data1.position",
    quote: "about.administration.data1.quote",
    img: gendir,
  },
  {
    name: "about.administration.data2.name",
    position: "about.administration.data2.position",
    quote: "about.administration.data2.quote",
    img: komdir,
  },
  {
    name: "about.administration.data3.name",
    position: "about.administration.data3.position",
    quote: "about.administration.data3.quote",
    img: glavtex,
  },
  {
    name: "about.administration.data4.name",
    position: "about.administration.data4.position",
    quote: "about.administration.data4.quote",
    img: tex,
  },
  {
    name: "about.administration.data5.name",
    position: "about.administration.data5.position",
    quote: "about.administration.data5.quote",
    img: buxgalter,
  },
];

export default function AboutAdmin() {
  const [t] = useTranslation();

  return (
    <section className="about">
      <div className="about__header">
        <div className="about__overlay">
          <Container>
            <h1 className="animate__animated animate__fadeInRightBig animate__slow">
              ORIENT TECHNOLOGY
            </h1>

            <h2 className="animate__animated animate__fadeInRightBig animate__slow animate__delay-1s">
              {t("about.administration.intro.text")}
            </h2>

            <p className="animate__animated animate__fadeInRightBig animate__slow animate__delay-1s">
              {t("about.administration.intro.text2")}
            </p>
          </Container>
        </div>
      </div>

      <Container className="py-5 about__administration">
        <div>
          {admins.map(({ img, name, position, quote }, i) =>
            i % 2 !== 0 ? (
              <Row>
                <Col md={6} className="d-flex">
                  <img
                    width="100%"
                    src={img}
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    alt={name}
                  />
                </Col>
                <Col md={6}>
                  <h2>{t(name)}</h2>
                  <p>{t(position)}</p>
                  <p>{t(quote)}</p>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={6}>
                  <h2>{t(name)}</h2>
                  <p>{t(position)}</p>
                  <p>{t(quote)}</p>
                </Col>
                <Col md={6} className="d-flex">
                  <img
                    width="100%"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    src={img}
                    alt={name}
                  />
                </Col>
              </Row>
            )
          )}
        </div>
      </Container>
    </section>
  );
}

import { TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";

import InputMask from "react-input-mask";

import ApplicationService from "../../services/ApplicationService";

import "./MyForm.scss";

function MyForm(props) {
  const application = new ApplicationService();

  const submit = (event) => {
    event.preventDefault();

    let phone = document.querySelector("#modal-phone").value;
    let email = document.querySelector("#modal-email").value;
    let name = document.querySelector("#modal-name").value;
    application.createApplication(phone, email, name);
  };

  const [t] = useTranslation();

  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#000",
      },
      "& fieldset": {
        borderColor: "#adadad",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
    },
  };

  return (
    <Row className="my-form">
      <Col className="my-form-right">
        <h2>{t("contacts.form.title")}</h2>
        {/* <h2>{t("contacts.form.header")}</h2> */}
        <Form onSubmit={submit}>
          <Row className="mb-5">
            <Col xs={12} className="mb-4">
              <TextField
                fullWidth
                label={t("contacts.form.name")}
                name="name"
                sx={style}
                id="modal-name"
                required
              />
            </Col>
            <Col xs={6} className="mb-4">
              <InputMask
                mask="+\9\9\8 \(99) 999-99-99"
                maskChar={null}
                name="number"
                fullWidth
                required
              >
                {(inputProps) => (
                  <TextField
                    id="modal-phone"
                    sx={style}
                    label={t("contacts.form.number")}
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Col>
            <Col xs={6} className="mb-4">
              <TextField
                fullWidth
                sx={style}
                id="modal-email"
                label={t("contacts.form.mail")}
                name="email"
                type="email"
                required
              />
            </Col>
            <Col xs={12} className="mb-4">
              <TextField
                fullWidth
                sx={style}
                label={t("contacts.form.sms")}
                name="description"
                rows={3}
              />
            </Col>
            <div>
              <button type="submit" className="button border-0">
                {t("contacts.form.send")}
              </button>
            </div>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default MyForm;

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import "./AboutSection.scss";

const data = [
  {
    icon: <MilitaryTechIcon />,
    title: "index.about.data0.title",
    parag: "index.about.data0.parag",
    animation: "fade-right",
  },
  {
    icon: <CheckCircleOutlineIcon />,
    title: "index.about.data1.title",
    parag: "index.about.data1.parag",
    animation: "fade-up",
  },
  {
    icon: <PersonOutlineOutlinedIcon />,
    title: "index.about.data2.title",
    parag: "index.about.data2.parag",
    animation: "fade-left",
  },
];

function AboutSection() {
  const { t } = useTranslation();

  return (
    <section className="aboutsection">
      <Container>
        <h5 className="animate__animated animate__fadeInRightBig">
          Orient Technology
        </h5>

        <h3 className="animate__animated animate__fadeInRightBig">
          {t("index.about.title")}
        </h3>
        <Row>
          {data.map((item, index) => (
            <Col md={4} key={index}>
              <div className="aboutsection__box" data-aos={item.animation}>
                {item.icon}
                <h6>{t(item.title)}</h6>
                <p>{t(item.parag)}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default AboutSection;

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import news1 from "../../resources/img/News/news1.jpg";
import news2 from "../../resources/img/News/news2.jpg";
import news3 from "../../resources/img/News/news3.jpg";
import news4 from "../../resources/img/News/news4.jpg";
import news5 from "../../resources/img/News/news5.jpg";
import news6 from "../../resources/img/News/news6.jpg";
import "./News.scss";

const data = [
  {
    img: news1,
    title: "news.data0.title",
    parag: "news.data0.parag",
  },
  {
    img: news2,
    title: "news.data1.title",
    parag: "news.data1.parag",
  },
  {
    img: news3,
    title: "news.data2.title",
    parag: "news.data2.parag",
  },
  {
    img: news4,
    title: "news.data3.title",
    parag: "news.data3.parag",
  },
  {
    img: news5,
    title: "news.data4.title",
    parag: "news.data4.parag",
  },
  {
    img: news6,
    title: "news.data5.title",
    parag: "news.data5.parag",
  },
];

export default function News() {
  const [t] = useTranslation();

  return (
    <section className="news">
      <div className="news__header">
        <div className="news__overlay">
          <Container>
            <h1 className="animate__animated animate__fadeIn animate__slow">
              {t("news.title")}
            </h1>
            <h4 className="animate__animated animate__fadeInRightBig animate__slow">
              {t("news.header")}
            </h4>
          </Container>
        </div>
      </div>

      <Container>
        <Row>
          {data.map((item, index) => (
            <Col md={4} key={index} className="mb-4">
              <div className="news__box h-100">
                <div className="news__box-parag">
                  <img src={item.img} width="100%" height="250" alt="?" />
                  <h3>{t(item.title)}</h3>

                  <p>
                    <div dangerouslySetInnerHTML={{ __html: t(item.parag) }} />
                  </p>
                </div>
                {/* <Link className='link-button' to="/">«ЧИТАТЬ ДАЛЕЕ»</Link> */}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
